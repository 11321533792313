import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

setTimeout(() => {
	Sentry.init({
		dsn: 'https://0d07d84f59d302743f00a58865acf8fa@o4508108922290176.ingest.de.sentry.io/4508109858340944',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		integrations: [replayIntegration()]
	})
}, 1000)

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
